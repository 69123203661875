<script lang="ts" setup>
const { t: $t } = useI18n()

const props = defineProps<{
  label?: string
  subLabel?: string
  value?: number
  info?: string
  isEstimate?: boolean
}>()
const formattedValue = computed(() => {
  if (!props.value) {
    return $t('customerModule.machinery.partnerSalePrice.placeholder')
  }
  return props.isEstimate ? $t('shop.checkout.priceSummary.estimate', { price: props.value.toLocaleString('de-DE', { minimumFractionDigits: 2 }), unit: $t('unit.eur') }) : `${props.value.toLocaleString('de-DE', { minimumFractionDigits: 2 })} ${$t('unit.eur')}`
})
</script>

<template>
  <li class="flex items-starts justify-between text-base">
    <div class="flex gap-2">
      <slot v-if="$slots.title" name="title" />
      <div v-else>
        <p>
          {{ label }}
        </p>
        <p v-if="subLabel" class="text-sm text-gray-500">
          {{ subLabel }}
        </p>
      </div>
      <n-tooltip v-if="info" trigger="hover">
        <template #trigger>
          <Icon name="material-symbols:info-outline-rounded" class="text-blue-500 mt-1" />
        </template>
        {{ info }}
      </n-tooltip>
    </div>
    <span class="font-semibold">
      {{ formattedValue }}
    </span>
  </li>
</template>
